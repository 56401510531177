body {
  font-family: "Inter", sans-serif, sans-serif;
}

.savedUserTable {
  width: 82rem;

  border-radius: 8px 8px 0 0;

  margin-top: 1rem;
}

.savedUserTable.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d0d0d0;
}

.savedUserTable thead .ant-table-cell {
  background-color: #009efd !important;
  color: white !important;
  line-height: 1;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

.savedUserTable .ant-table-wrapper table {
  border-left: #d0d0d0 solid 1px;
  border-right: #d0d0d0 solid 1px;
}

.usersEmail .ant-alert {
  width: fit-content;
}

.saveduserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}
.savedUserTable.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}

#addUserBtn {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  /* margin-top: 2rem; */

  border: none;
  background-color: #f9f9fb;
  color: #009efd;
}
#emailInput {
  width: 13rem;
  height: 2.5rem;
}
#email {
  font-family: "Inter", sans-serif;
}
.usersAddButton {
  text-align: end;
}
.usersAddButton.ant-btn.ant-btn-lg {
  border-radius: 4px;
}
#usersAdd {
  background-color: #109cf1;
  width: 5rem;
  height: 2.5rem;
}
#fdnameError {
  color: #ee3248;
  font-size: 10px;
}
#friendlyNameId {
  font-size: 10px;
}
.selectUser {
  width: 100% !important;
  min-height: 3.5rem !important;
}

.usersEmail {
  white-space: pre-line;
}

.userDrawer .ant-drawer-header-title {
  flex-direction: row-reverse !important;
}
.deleteUserIcon {
  background: url("../images/icons.png") -239px -235.9px;
}

.deleteUserIcon:hover {
  background: url("../images/icons.png") -111px -232.9px;
}
.searchBoxInSelectComponent {
  border-bottom: 1px solid #d0d0d0 !important;
  height: 50px;
  border-radius: 0px;
}
.selectUser .ant-select-selection-item {
  font-weight: 500 !important;
}

.user-select-tab {
  border: none !important;
}

.user-select-tab .anticon-close {
  font-size: larger;
  color: black;
}
.users-number-list {
  min-height: 25px !important;
  min-width: 25px;
  display: inline-block;
  vertical-align: middle;
}

.users-number-list-ringcentral {
  background-position: -29.65rem -5.41rem;
}

.users-number-list-twilio {
  background-position: -36.876rem -5.425rem;
}
.users-number-list-hello_send {
  background-position: -29.65rem -7.21rem;
}
.users-number-list-whatsapp {
  background-position: -31.45rem -7.01rem;
}
.assign-phonenumbers {
  background-color: #f3f4f6 !important;
}
.roles-based-number {
  max-height: 15%;
  overflow-y: auto;
}
.users-configuration-collapse.ant-collapse .ant-collapse-content {
  background-color: #f8f7ff !important;
  border-left: 3px solid var(--hs-violet) !important;
}
.users-configuration-collapse .ant-collapse-content {
  border-radius: 8px !important;
}
.add-user-drawer-messaging-icon {
  background-position: 85% 7%;
  background-size: 650px 650px;
}
.add-user-drawer-voice-icon {
  background-position: 85% 2%;
  background-size: 650px 650px;
}
.users-configuration-collapse .ant-collapse-item > .ant-collapse-header {
  padding: 15px 0px;
}
.users-voice-permission-select .ant-select-selector {
  background-color: inherit !important;
}
.users-voice-permission-select .ant-select-selection-placeholder {
  font-size: 12px !important;
}

.users-voice-permission-select .ant-select-selector {
  font-size: 12px !important;
}
.users-voice-permission-select {
  width: 100px !important;
}
.users-selectedTeams-list .ant-list-item-action-split {
  display: none;
}
.users-selectedTeams-list.ant-list-split .ant-list-item {
  border: none !important;
  padding: 5px 0;
}
.users-selectedTeams-list .ant-spin-container {
  max-height: 212px !important;
  overflow: auto;
}
.hs-tag-item.ant-tag {
  padding-inline: 16px;
  border-radius: 15px;
  border: 1px solid var(--hs-light-silver);
}
.users-tag-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 125px;
}
.common-permissions-icon {
  background-position: -32.95rem -5.25rem;
  min-width: 25px;
}
.message-permissions-icon {
  background-position: -32.8rem -2.89rem;
  min-width: 25px;
}
.voice-permissions-icon {
  background-position: -32.6rem -0.6rem;
  min-width: 25px;
}
.custom-collapse .ant-collapse-item {
  border: none !important;
}
.custom-collapse .ant-collapse-header {
  background: #ffffff !important;
  padding: 1% 0% !important;
}

.custom-collapse .ant-collapse-content {
  border-top: none !important;
}

.custom-collapse .ant-collapse-content-box {
  padding: 0 !important;
}
.permission-header {
  display: flex !important;
  align-items: center !important;
  white-space: nowrap !important;
}
.permission-header .icon {
  margin-right: 2% !important;
}

.userModel #hs-label-and-value {
  margin-top: 20px !important;
}
.custom-select-dropdown .ant-select-dropdown {
  border: none !important;
  box-shadow: none !important;
}

.custom-select-dropdown .ant-select-item {
  border: none !important;
}

/* Custom styles for Ant Design Select dropdown items */
.custom-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f3f3ff !important; /* Change hover background color to purple */
}

.custom-select-dropdown .ant-select-item-option-selected {
  background-color: #f3f3ff !important; /* Change active (selected) background color to purple */
}

/* Add padding to the footer to increase the margin */
.userDrawer .ant-drawer-footer {
  padding: 16px 24px; /* Adjust the padding as needed */
}

/* Remove the border from the footer */
.userDrawer .ant-drawer-footer {
  border-top: none !important;
}

/* Shared styles for both buttons */
.rolesBtn {
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px !important;
  height: 44px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Custom styles for the cancel button */
.cancelRolesBtn.ant-btn:not(:disabled):not(.ant-btn-disabled):hover {
  color: #605bff !important; /* Text color on hover */
  border-color: #605bff !important; /* Border color on hover */
}

/* Custom styles for Ant Design Tabs */
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #605bff !important; /* Text color for the active tab */
}

.custom-tabs .ant-tabs-ink-bar {
  background-color: #605bff !important; /* Color of the line under the active tab */
}

.custom-tabs .ant-tabs-tab:hover {
  color: #605bff !important; /* Text color on hover */
}
